import React, { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import api from '../../services/api';
import loginlogo from '../../assets/login-logo.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { setUser, setToken } = useContext(AuthContext);
  const navigate = useNavigate();

  const mutation = useMutation(async (loginData) => {
    setLoading(true);
    const response = await api.post('/users/login', loginData);
    return response.data;
  }, {
    onSuccess: (data) => {
      setLoading(false);
      setUser(data.user);
      setToken(data.token);
      localStorage.setItem('token', data.token);
      localStorage.setItem('orgId', data.user.orgId);
      navigate('/projects');
    },
    onError: (error) => {
      setLoading(false);
      setError(error.response?.data?.message || 'Login failed');
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    mutation.mutate({ email, password });
  };

  const handleSignupClick = () => {
    navigate('/signup');
  };

  return (
      

    <div  className="flex min-h-screen w-full">
    <div className="flex-1 bg-purple-600 w-1/2 p-12 flex items-center">
        <div className="max-w-2xl">
            <h1 className="text-5xl font-bold text-white mb-6">Revolutionize Document Management with Automation</h1>
            <p className="text-purple-200">Automate document management to boost efficiency, reduce errors, and focus on strategic goals.</p>
        </div>
    </div>
    
    <div className="flex-1 w-1/2 items-center justify-center">
    <div className="p-12 flex items-center justify-center">
        <div className="w-full max-w-md">
            <div className="flex items-center mb-8">
                <div className="flex items-center justify-center w-full">
                <img src={loginlogo} alt="Neo" className='w-40' />
                </div>
               
            </div>
            
            <div className="bg-white rounded-lg border p-8">
                <h2 className="text-xl font-bold text-center mb-1">Welcome Back 👋</h2>
                <p className="text-gray-600 mb-6 text-sm text-center">Ready to continue where you left off? Log in to access your account and achieve more today."</p>
                
                <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <p className='text-sm mb-1'>Email</p>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email"
              required
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4 relative">
          <p className='text-sm mb-1'>Password</p>
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              required
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-600"
            >
              {showPassword ? 'Hide' : 'Show'}
            </button>
          </div>
          <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
                            <input type={"checkbox"} id={"remember"} className="mr-2" />
                            <label htmlFor={"remember"} className="text-sm text-gray-600">Remember Me?</label>
                        </div>
                        <Link to="/forgotpassword" className="text-base text-blue-600 hover:underline font-medium">Forgot Password?</Link>
                    </div>
          <div className="flex justify-between items-center">
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-300 mr-2"
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Login'}
            </button>
           
          </div>
          {error && <p className="mt-4 text-red-500">{error}</p>}
        
                
                <p className="text-center mt-6 text-base text-gray-600">
                    Don't have an account? <Link to='/signup'  className="text-blue-600 hover:underline">Create an account</Link >
                </p>
                </form>
            </div>
        </div>
       
    </div>
    <div className='text-xs text-center w-full'>© 2024 NEO India, Inc. All rights reserved.</div>
    </div>
    </div>




    
  );
};

export default Login;

// src/context/ProjectContext.js
import React, { createContext, useState, useEffect } from 'react';
import { getAllProjects } from '../services/projectApi';

// Create the context
export const ProjectContext = createContext();

// Create a provider component
export const ProjectProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // Fetch projects from the database when the component mounts
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
        try {
          const response = await getAllProjects();
          console.log(response)
          const data = response;
          setProjects(data);
        } catch (error) {
          console.log('Failed to fetch documents', error);
        }
  };


  const addProject = (newProject) => {
    const newProjectsList= (prevProjects) => [newProject, ...prevProjects]
    setProjects(newProjectsList);
  };

  const editProject = (updatedProject) => {
    const updatedProjects = projects.map((project) =>
      project._id === updatedProject._id ? updatedProject : project
    );
   setProjects(updatedProjects);
  
  };

  const deleteProject = (projectId) => {
    setProjects((prevProjects) =>
      prevProjects.filter((project) => project.id !== projectId)
    );
  };

  return (
    <ProjectContext.Provider
      value={{ projects, addProject, editProject, deleteProject }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

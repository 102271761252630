import React, { useEffect, useState } from 'react';
import DesignTemplate from './DesignTemplate';
import NeoModal from './NeoModal';
import GenerateDocument from './GenerateDocument';
import { FaMagic, FaSearch } from "react-icons/fa";
import { FaPenNib } from "react-icons/fa";



const SearchHeader = ({projectId, hasProject = false}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [displayPage, setDisplayPage] = useState("")
    const [isGenerateDocModalOpen, setIsGenerateDocModalOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState(projectId || '');

    const handleDesignTemplates = () => {
        setDisplayPage('designTemplates')
        setIsModalOpen(true);
    }
    const handleGenerateDocs = () => {
        setDisplayPage('generateDocs')
        setIsModalOpen(true);
    }
    useEffect(() => {
      if(projectId){
      setSelectedProject(projectId)
        }}, [projectId]);

  return (
    <div className="bg-gradient-to-r from-blue-500 to-purple-500 p-6 rounded-3xl shadow-lg flex justify-between items-center">
      <div className="text-white w-1/2">
        <h1 className="text-3xl font-normal banner">
          Revolutionize Document <br />
          <span className="text-teal-200 highlights">Management <span className='text-white font-normal'>with</span> Automation</span>
        </h1>
      </div>

      <div className="items-center w-1/2">
       <div className='w-full'>
       <div className="relative w-full">
      <input
        type="text"
        placeholder="Search for template/documents"
        className="w-full search-text p-3 h-11 pl-10 rounded-lg shadow-inner text-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-600"
      />
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <FaSearch  className="text-gray-500 w-5 h-5" />
      </div>
    </div>
       
        </div>
        <div className='flex w-full justify-center pt-4'>
        <button className="ml-4 bg-white text-blue-600 font-medium py-2 px-4 rounded-lg shadow-md hover:bg-blue-100 flex items-center"
            onClick={handleDesignTemplates}>
         
          <FaPenNib className='adj'/> Design Template
        </button>
        <button className="ml-4 bg-white text-green-600 font-medium py-2 px-4 rounded-lg shadow-md hover:bg-green-100 flex items-center"
           onClick={handleGenerateDocs}>
         
          <FaMagic className='adj' /> Generate Documents
        </button>
        </div>
      </div>
      <NeoModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
       {displayPage === 'designTemplates' ? <DesignTemplate onClose={() =>  setIsModalOpen(false)} value ={selectedProject} hasProject ={hasProject} /> :
        <GenerateDocument onClose={() => setIsModalOpen(false)}  value ={selectedProject} hasProject ={hasProject}/>}
      </NeoModal>
    </div>
  );
};

export default SearchHeader;
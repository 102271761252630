import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { FaHome, FaBuilding, FaUser, FaSignOutAlt, FaBars, FaAngleDoubleLeft, FaAngleDoubleRight,FaRegFolderOpen,FaUpload, FaFileAlt, FaDownload, FaTrash, FaDotCircle } from 'react-icons/fa';

import { MdKeyboardArrowDown, MdArrowDropDown } from "react-icons/md";
import { RiMenuFill, RiLayout4Line } from "react-icons/ri";
import { IoNotifications } from "react-icons/io5";
import { BsSearch } from "react-icons/bs";
import { GoHome } from "react-icons/go";
import photo from '../assets/photo.png';
import { useNavigate } from 'react-router-dom';
import { ProjectContext } from '../context/ProjectContext';
import axios from "axios";

const Navigation = () => {
  const navigate = useNavigate();
  const { user, logout } = useContext(AuthContext);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
 // const [projects,setProjects] = useState([]);
  const [error,setError] = useState('');
  const [loading,setLoading] = useState('')
  const isActive = (path) => location.pathname === path;
  const { projects } = useContext(ProjectContext);
  
  const handleProjects = () => {
    navigate(`/projects`);
  }

  const handleTemplates = () => {
    navigate(`/Neo`);
  }

  const gotoHome = () => {
    navigate('/projects')
  }

  const handleProjectsTemplates = (project) => {
    navigate(`/projects/${project._id}`, { state: { data: project } });
  }

  return (
    <div className="relative h-full border-r flex flex-col w-64">
            <div className='flex flex-col items-start border-gray-200' >
        <div className="flex items-center w-64 h-20 border-b border-gray-300">
          <img src={photo} alt="Profile" className="w-12 h-12 rounded-full ml-2" />
          <div className="flex flex-col ml-4">
            <div className="text-sm font-semibold">Kevin Rangel</div>
            <div className="text-xs">Admin</div>
          </div>
          <MdArrowDropDown className='w-6 h-6 ml-4' />
        </div>
        <div className='mt-4 w-64 px-3'>
          <div className='flex items-center w-full pl-3 hover:bg-blue-100 rounded-lg cursor-pointer' onClick = {gotoHome}>
            <GoHome className='w-5 h-5' />
            <div className='ml-2 text-sm font-semibold py-2'><span >Home</span></div>
          </div>
          <div className='flex items-center w-full pl-3 mt-2 hover:bg-blue-100 rounded-lg cursor-pointer' onClick={handleProjects}>
            <FaRegFolderOpen className='w-5 h-5' />
            <div className='ml-2 text-sm text-gray-700 py-2'><span >Projects</span>
             
            </div>
          </div>
          {projects && projects.map((project)=>
                 <ul><li onClick={()=>handleProjectsTemplates(project)}>
                 <div className='flex items-center pl-8 mt-2 hover:bg-blue-100 rounded-lg cursor-pointer' key={project._id}>
                  <FaDotCircle className='w-2 h-2' />
                 <div className='ml-2 text-sm text-gray-700  py-2'>{project.projectName}</div>
               </div>
               </li></ul>
                )}
          <div className='flex items-center w-full pl-3 mt-2 hover:bg-blue-100 rounded-lg cursor-pointer'  onClick={handleTemplates}>
            <RiLayout4Line className='w-5 h-5' />
            <div className='ml-2 text-sm text-gray-700  py-2'>Template</div>
          </div>
          <div className='flex items-center w-full pl-3 mt-2 hover:bg-blue-100 rounded-lg cursor-pointer'  onClick={logout} >
             <FaSignOutAlt className="inline-block mr-2" />
            <div className='ml-2 text-sm text-gray-700  py-2'>Logout</div>
          </div>

        </div>
      </div>
      <nav className={`bg-gray-100 text-gray-800 ${isOpen ? 'w-52' : 'w-16'} hidden h-full border-r border-gray-300 transition-width duration-300`}>
        <ul className="space-y-2 p-4">
          {user && user?.features?.includes('viewDashboard') && (
            <li>
              <Link
                to="/dashboard"
                className={`block py-2 px-4 rounded hover:bg-gray-200 transition duration-200 ${
                  isActive('/dashboard') ? 'bg-gray-200' : ''
                }`}
              >
                <FaHome className="inline-block mr-2" /> {isOpen && 'Dashboard'}
              </Link>
            </li>
          )}
          {user && user?.features?.includes('viewOrganizations') && (
            <li>
              <Link
                to="/organizations"
                className={`block py-2 px-4 rounded hover:bg-gray-200 transition duration-200 ${
                  isActive('/organizations') ? 'bg-gray-200' : ''
                }`}
              >
                <FaBuilding className="inline-block mr-2" /> {isOpen && 'Organizations'}
              </Link>
            </li>
          )}
          {user && user?.features?.includes('viewProfile') && (
            <li>
              <Link
                to="/profile"
                className={`block py-2 px-4 rounded hover:bg-gray-200 transition duration-200 ${
                  isActive('/profile') ? 'bg-gray-200' : ''
                }`}
              >
                <FaUser className="inline-block mr-2" /> {isOpen && 'Profile'}
              </Link>
            </li>
          )}
          {user && (
            <li>
              <button
                onClick={logout}
                className="w-full text-left py-2 px-4 rounded hover:bg-gray-200 transition duration-200 flex items-center"
              >
                <FaSignOutAlt className="inline-block mr-2" /> {isOpen && 'Logout'}
              </button>
            </li>
          )}
        </ul>
      </nav>
      <button
        className={`hidden absolute top-1/2 transform -translate-y-1/2 ${isOpen ? 'right-1' : 'right-32'} bg-gray-200 text-gray-600 p-1 rounded-full focus:outline-none hover:bg-gray-300 transition duration-200`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? <FaAngleDoubleLeft size={20} /> : <FaAngleDoubleRight size={20} />}
      </button>
    </div>

  );
};

export default Navigation;

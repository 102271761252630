import React, { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import api from '../../services/api';
import loginlogo from '../../assets/login-logo.png';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setCpassword] = useState('');
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [accept, setAccept] = useState('');
  const [error, setError] = useState(null);
  const { setUser, setToken } = useContext(AuthContext);
  const navigate = useNavigate();

  const mutation = useMutation(async (userData) => {
    const response = await api.post('/users/signup', userData);
    return response.data;
  }, {
    onSuccess: (data) => {
      console.log('Signup successful:', data);
      setUser(data.user);
      setToken(data.token);
      localStorage.setItem('token', data.token);
      localStorage.setItem('orgId', data.user.orgId);
      navigate('/projects');
    },
    onError: (error) => {
      console.error('Signup error:', error);
      setError(error.response?.data?.message || 'Signup failed');
    }
  });

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return emailPattern.test(email);
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Reset error state before attempting signup

    
    // Email validation
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    // Password validation
    if (password.length < 8) {
      setError('Password must be at least 8 characters long.');
      return;
    }

    // Confirm Password validation
    if (password !== confirmpassword) {
      setError('Passwords do not match.');
      return;
    }

    const userData = {
      user: { name, email, password, confirmpassword, accept },
      mobile: { name: mobile }
    };
    mutation.mutate(userData);
  };
  return (
    <div className="flex min-h-screen w-full">
          <div className="flex-1 bg-purple-600 w-1/2 p-12 flex items-center">
        <div className="max-w-2xl">
            <h1 className="text-5xl font-bold text-white mb-6">Revolutionize Document Management with Automation</h1>
            <p className="text-purple-200">Automate document management to boost efficiency, reduce errors, and focus on strategic goals.</p>
        </div>
    </div>


    <div className="flex-1 w-1/2 items-center justify-center">
    <div className="p-12 flex items-center justify-center">
        <div className="w-full max-w-md">
        <div className="flex items-center mb-8">
                <div className="flex items-center justify-center w-full">
                <img src={loginlogo} alt="Neo" className='w-40' />
                </div>  
            </div>

            <div className="bg-white rounded-lg border p-8">
            <h2 className="text-xl font-bold text-center mb-1">Welcome NEO 👋</h2>
                <p className="text-gray-600 mb-6 text-sm text-center">Join us! Sign up to start your journey and unlock exclusive features. Create your account today and get started!</p>
                
        <form onSubmit={handleSubmit}>
         
          <div className="mb-4">
            <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">Full Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" placeholder="Enter full name" required />
           </div>
          <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email Address</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" placeholder="Enter email address" required />
          </div>
          <div className="mb-4">
          <label htmlFor="mobile" className="block text-sm font-medium text-gray-700">Mobile Number</label>
            <input
              type="mobile"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" placeholder="Enter mobile number" required />
          </div>
          <div className="mb-4">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" placeholder="Enter password" required />
          <p className="text-xs text-gray-500 mt-1">Use 8 or more characters with a mix of letters, numbers & symbols.</p>
          </div>
          <div className="mb-4">
          <label htmlFor="confirmpassword" className="block text-sm font-medium text-gray-700">Confirm Password</label>
            <input
              type="confirmpassword"
              value={confirmpassword}
              onChange={(e) => setCpassword(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" placeholder="Enter confirm password" required />
          </div>
          <div className="mb-4">
            <label className="flex items-center">
              <input 
              type="checkbox"
              name="acceptTerms" 
              checked={accept} 
              onChange={(e) => setAccept(e.target.value)} 
              className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
              <span className="ml-2 text-sm text-gray-600">I Accept the <a href="#" className="text-blue-600 hover:underline">Terms & Conditions</a></span>
            </label>
          </div>
          <button
            type="submit"
            className="w-full bg-green-500 text-white py-2 rounded-lg hover:bg-green-600 transition duration-300"
          >
            Create Account
          </button>
          {error && <p className="mt-4 text-red-500">{error}</p>}
          <div className='text-sm text-gray-600  flex items-center justify-center mt-2'>Already have an account? <a href='/login' className='text-blue-600 hover:underline pl-4'>Log In</a></div>

        </form>
        </div>
       
       </div>
      
       </div>
       <div className='text-xs text-center w-full'>© 2024 NEO India, Inc. All rights reserved.</div>
       </div>
       </div>
  );
};

export default Signup;
